import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';
import '../scss/global.scss';
import '../scss/header.scss';
import '../scss/home.scss';

window.addEventListener('load', () => {
  const $ = window.$;

  const swiper = new Swiper('.swiper-container', {
    auto: true,
    loop: true, // 循环模式选项
    // preventClicks: false,
    // preventClicksPropagation: false,

    // 如果需要分页器
    pagination: {
      el: '.swiper-pagination',
    },

    // 如果需要前进后退按钮
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  $('.home-banner').on('click', '.swiper-button-prev', (e) => {
    console.log(e.target);
    swiper.slidePrev();
  });
  $('.home-banner').on('click', '.swiper-button-next', (e) => {
    console.log(e.target);
    swiper.slideNext();
  });
});
